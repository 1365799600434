import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import OverTime from "./SubPages/OverTime";
import ByClient from "./SubPages/ByClient";
import ByRep from "./SubPages/ByRep";
import ByJobOrder from "./SubPages/ByJobOrder";
import ByGoals from "./SubPages/ByGoals";
import BySingleActivity from "./SubPages/BySingleActivity";
import OverTimeByRep from "./SubPages/OverTimeByRep";
import "./style.scss";

function ActivityRecruiting() {
  return (
    <>
      <PageNav
        title="Activity (Recruiting)"
        links={[
          { label: "Over Time", href: "over-time" },
          { label: "By Client", href: "by-client" },
          { label: "By Rep", href: "by-rep" },
          { label: "By Job Order", href: "by-job-order" },
          { label: "By Goals", href: "by-goals" },
          { label: "By Single Activity", href: "by-single-activity" },
          { label: "Over Time By Rep", href: "over-time-by-rep" }
        ]}
      />
      <div className="page">
        <Routes>
          <Route path={`over-time`} element={<OverTime />} />
          <Route path={`by-client`} element={<ByClient />} />
          <Route path={`by-rep`} element={<ByRep />} />
          <Route path={`by-job-order`} element={<ByJobOrder />} />
          <Route path={`by-goals`} element={<ByGoals />} />
          <Route path={`by-single-activity`} element={<BySingleActivity />} />
          <Route path={`over-time-by-rep`} element={<OverTimeByRep />} />
          <Route index element={<Navigate to={`over-time`} />} />
        </Routes>
      </div>
    </>
  );
}

export default ActivityRecruiting;
