import { useState, useEffect, useMemo, useContext } from "react";
import { roles } from "../../../../Utilities/constants";
import { EmailContext } from "../../../../Contexts/emailContext";
import { AuthContext } from "../../../../Contexts/authContext";
import useParams from "../../../../Hooks/useParams";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";

function OverTimeByRep() {
  const { emails } = useContext(EmailContext);
  const { user } = useContext(AuthContext);

  const { updateParams } = useParams();

  const [id, setId] = useState();
  
  const isAdmin = useMemo(() => roles.indexOf(user.role) >= roles.indexOf("admin"), [user]);

  useEffect(() => {
    if (id || !emails.length || !user) return;
    if (!isAdmin) return setId(user.id);
    setId(emails[0].id);
  }, [id, user, isAdmin, emails]);

  useEffect(() => {
    updateParams({ userId: id });
  }, [id, updateParams])

  const dataURL = '/api/v1/activity/recruiting/time-rep'
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const total = useMemo(() => {
    if (!data) return 0;
    let sum = 0;
    for (let key in data.data) {
      sum += Object.values(data.data[key]).reduce((acc, cur) => (typeof cur === "string" ? acc : acc + cur), 0);
    }

    return sum.toLocaleString();
  }, [data]);

  const nameElement = useMemo(() => {
    if (isAdmin) return (
      <select className="page-user-select" value={id} onChange={e => setId(e.target.value)}>
        {emails.map(email => <option value={email.id} key={email.label}>{email.label}</option>)}
      </select>);
    return <span className="page-data">{user.firstName} {user.lastName}</span>
  }, [id, isAdmin, user.firstName, user.lastName, emails]);

  return (
    <GraphTable data={data} dateRange={dateRange} interval={dateRange.interval} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">How much are we working?</h1>
      <h2 className="page-subheading">
        {nameElement} had <span className="page-data">{total}</span> total activities <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );
}

export default OverTimeByRep;
